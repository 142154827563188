<template>
  <div>Loading...</div>
</template>

<script>
import hello from "hellojs/dist/hello.all.js";

export default {
  name: "callback",
};
</script>